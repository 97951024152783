<div class="surface-ground container mx-auto" role="main">
  <div class="mx-auto max-w-[720px] px-2 pb-6 pt-2" *ngIf="!isLoading">
    <div class="linear max mx-auto mb-16 transition-all duration-500">
      <p-card styleClass="padding-clear">
        <form [formGroup]="carpoolFormGroup" (ngSubmit)="onSubmit()">
          <div class="grid grid-cols-1 gap-x-6 gap-y-8 p-4 sm:grid-cols-6">
            <div
              class="col-span-6 grid grid-cols-1 gap-x-6 gap-y-4 md:col-span-6"
            >
              <div
                class="col-span-6 flex flex-col gap-3 md:col-span-6 md:flex-row md:gap-0"
                [ngClass]="{ 'mb-3': !isViewMode }"
              >
                <div class="flex-1">
                  <span class="form-title block leading-6"
                    >Carpool Leader
                    <p-button
                      class="inline-block align-text-bottom"
                      label="(Edit)"
                      *ngIf="isLeader && !isPastCarpool"
                      styleClass="primary ml-3"
                      [text]="true"
                      (click)="onEdit()"
                    ></p-button>
                  </span>
                  <div class="mt-2 flex items-center gap-2">
                    <p-avatar styleClass="mr-2" size="large" shape="circle">
                      <img
                        *ngIf="leader?.profilePicUrl && userInfo?.profilePicUrl"
                        [src]="
                          getProfileImageById(
                            leader?.id ? leader.id : userInfo.id
                          )
                            | image
                            | async
                        "
                        alt=""
                      />
                      <img
                        *ngIf="!leader.profilePicUrl || !userInfo.profilePicUrl"
                        src="../assets/images/user-pic-default.svg"
                        alt="default"
                      />
                    </p-avatar>
                    <div>
                      {{
                        leader?.displayName
                          ? leader?.displayName
                          : userInfo.displayName
                      }}
                    </div>
                    <div>(Leader)</div>
                  </div>
                </div>
                <div class="w-full flex-1">
                  <span class="mb-1 block flex items-center">
                    <span class="form-title">Carpoolers</span>
                    <p-button
                      label="Invite"
                      styleClass="ml-3 primary"
                      [text]="true"
                      *ngIf="
                        userInfo.id === leader?.id &&
                        !this.carpoolFormGroup.get('huddles')?.value?.huddle
                          ?.id &&
                        !isPastCarpool
                      "
                      (click)="onInviteClick()"
                    ></p-button>
                  </span>
                  <p-scrollPanel
                    class=""
                    [style]="{
                      width: '100%',
                      maxHeight: '150px',
                      height: carpoolList.length > 1 ? '150px' : '50px',
                    }"
                  >
                    <div class="selected-users rounded-md">
                      <ul role="list" class="">
                        <li
                          *ngFor="let carpooler of carpoolList"
                          class="grow-1 flex-stretch ml-12 flex items-center justify-between pb-2"
                        >
                          <div class="grow-1 -ml-12 flex w-full items-center">
                            <p-avatar
                              styleClass="mr-2"
                              size="large"
                              shape="circle"
                            >
                              <img
                                *ngIf="carpooler.member?.profilePicUrl"
                                [src]="
                                  getProfileImageById(carpooler.member?.id)
                                    | image
                                    | async
                                "
                                alt=""
                              />
                              <img
                                *ngIf="!carpooler.member?.profilePicUrl"
                                src="../assets/images/user-pic-default.svg"
                                alt="default"
                              />
                            </p-avatar>
                            <span class="basis-full">{{
                              carpooler.member.displayName
                            }}</span>
                            <p-button
                              *ngIf="
                                isLeader && carpooler.member.id !== userInfo.id
                              "
                              [rounded]="true"
                              [text]="true"
                              type="button"
                              (click)="
                                onCarpoolersRemove(
                                  carpooler.id,
                                  carpooler.member.id
                                )
                              "
                              styleClass="justify-center h-6 w-6 mp-button mp-button-rounded"
                            >
                              <span class="material-icons text-white"
                                >close</span
                              >
                            </p-button>
                            <div
                              class="ml-2 flex items-center gap-2"
                              *ngIf="
                                carpooler.member.id === userInfo.id &&
                                !isAcceptOrDeny &&
                                carpooler.joinRequestStatus !== 'APPROVED'
                              "
                            >
                              <p-button
                                [text]="true"
                                (click)="onInviteAction('ACCEPT')"
                                class="primary"
                              >
                                Accept
                              </p-button>
                              <p-button
                                [text]="true"
                                (click)="onInviteAction('DENY')"
                                class="primary"
                              >
                                Deny
                              </p-button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </p-scrollPanel>
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <span class="form-title block leading-6"
                  >Communication Channel URL</span
                >
                <div [ngClass]="{ 'mt-2': !isViewMode }">
                  <a
                    [href]="
                      this.carpoolFormGroup.get('externalCommChannelUrl')?.value
                    "
                    target="_blank"
                  >
                    <span *ngIf="isViewMode" class="text-base">{{
                      this.carpoolFormGroup.get("externalCommChannelUrl")
                        ?.value || "-"
                    }}</span>
                  </a>
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <span class="form-title block leading-6">Huddle</span>
                <div [ngClass]="{ 'mt-2': isViewMode }">
                  <span
                    >{{
                      this.carpoolFormGroup.get("huddles")?.value?.huddle
                        ?.name || "-"
                    }}
                  </span>
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <span class="form-title block leading-6">Vehicles</span>
                <div [ngClass]="{ 'mt-2': !isViewMode }">
                  <span *ngIf="isViewMode">{{
                    this.carpoolFormGroup.get("vehicle")?.value?.name || "-"
                  }}</span>
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <span
                  class="form-title block text-base leading-6 dark:text-slate-500"
                  >Trip Type
                </span>
                <div [ngClass]="{ 'mt-2': !isViewMode }">
                  <span *ngIf="isViewMode" class="text-base">{{
                    this.carpoolFormGroup.get("tripType")?.value.name || "-"
                  }}</span>
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <span class="form-title block leading-6"
                  >Start Date
                  <span class="text-red-400" *ngIf="!isViewMode">*</span></span
                >
                <span *ngIf="isViewMode">
                  {{
                    this.carpoolFormGroup.get("startTime")?.value
                      | date: "EEEE, MMMM d, y, h:mm a"
                  }}
                </span>
              </div>

              <div class="col-span-6 md:col-span-6" *ngIf="isRound">
                <span class="form-title block leading-6">Return Date</span>
                <span *ngIf="isViewMode && isRound">{{
                  this.carpoolFormGroup.get("returnTime")?.value
                    | date: "EEEE, MMMM d, y, h:mm a"
                }}</span>
              </div>

              <div class="col-span-6 md:col-span-6">
                <div class="">
                  <app-address
                    label="Start Address"
                    [hideMap]="true"
                    [isValidate]="true"
                    (renderAddress)="renderAddress()"
                    [showChange]="false"
                    [events]="startAddressSubject.asObservable()"
                    (addressChange)="onStartAddressChange($event)"
                  ></app-address>
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <div class="">
                  <app-address
                    label="End Address"
                    [hideMap]="true"
                    [isValidate]="true"
                    (renderAddress)="renderAddress()"
                    [showChange]="false"
                    [events]="endAddressSubject.asObservable()"
                    (addressChange)="onEndAddressChange($event)"
                  ></app-address>
                </div>
              </div>

              <div
                class="col-span-6 md:col-span-6"
                [ngClass]="{
                  hidden: !(
                    this.carpoolFormGroup.get('endAddress')?.value &&
                    this.carpoolFormGroup.get('startAddress')?.value
                  ),
                }"
              >
                <div class="mt-2">
                  <div id="gmap-route" class="gmap-route h-[250px]"></div>
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <span class="form-title block leading-6"
                  >Seating Capacity of the Vehicle
                  <span class="text-red-400" *ngIf="!isViewMode">*</span></span
                >
                <span *ngIf="isViewMode">{{
                  this.carpoolFormGroup.get("seatingCapacity")?.value || "-"
                }}</span>
              </div>

              <div class="col-span-6 md:col-span-6">
                <span class="form-title block leading-6">Notes</span>
                <span *ngIf="isViewMode">{{
                  this.carpoolFormGroup.get("notes")?.value || "-"
                }}</span>
              </div>

              <div
                class="col-span-6 md:col-span-6"
                *ngIf="userInfo.preference?.betaUser"
              >
                <div class="mt-2">
                  <div class="flex flex-1 gap-4 rounded-md">
                    <span class="form-title block leading-6"
                      >One way allowed</span
                    >
                    <span *ngIf="isViewMode">{{
                      this.carpoolFormGroup.get("oneWayAllowed")?.value
                        ? "Yes"
                        : "No"
                    }}</span>
                  </div>
                </div>
              </div>

              <div
                class="col-span-6 md:col-span-6"
                *ngIf="userInfo?.preference?.betaUser"
              >
                <div class="mt-2">
                  <div class="flex flex-1 gap-4 rounded-md">
                    <span class="form-title block leading-6"
                      >Join request required</span
                    >
                    <span *ngIf="isViewMode">{{
                      this.carpoolFormGroup.get("joinRequestRequired")?.value
                        ? "Yes"
                        : "No"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </p-card>
    </div>
  </div>
</div>
<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>

<app-dialog
  [visibleChange]="dialogEvent.asObservable()"
  [view]="'CARPOOL'"
  header="Edit"
  [userDetails]="userInfo"
  width="50vw"
  styleClass="max-h-full md:max-h-[revert-layer] carpool-dialog"
  [fullWidth]="true"
  [fullHeight]="true"
  (huddleDialogData)="onHuddleData($event)"
></app-dialog>

<p-dialog
  header="Select User"
  [(visible)]="userPickerVisible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [breakpoints]="{ '960px': '80vw' }"
  [draggable]="false"
  [resizable]="false"
  [appendTo]="'body'"
>
  <div #inviteUser class="relative mb-2 overflow-hidden transition-[height]">
    <app-user-picker-new
      [ref]="inviteUser"
      [showSave]="true"
      [buttonText]="'Invite'"
      (list)="onSelectedUser($event)"
      (remove)="onRemoveUser($event)"
      (submitUserPicker)="onInviteUser()"
      [events]="userPickerEvent.asObservable()"
    ></app-user-picker-new>
  </div>
</p-dialog>
