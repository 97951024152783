import { ActivatedRoute } from '@angular/router';
import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject, Subscription, tap } from 'rxjs';
import { HuddleService } from 'src/app/main/services/huddle.service';
import { VehicleService } from 'src/app/main/services/vehicle.service';
import {
  Huddlers,
  DialogEvent,
  Carpool,
  UserDetails,
  Vehicle,
  TripType,
  Huddle,
  User,
} from 'src/app/main/types/main.types';
import { initMap } from 'src/app/main/utilities';
import { roles } from 'src/app/main/utilities/sidebarHelper';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/main/store/model/state.model';

enum TripTypeEnum {
  R = 'ROUND',
  D = 'DEPART',
  RT = 'RETURN',
}

@Component({
  selector: 'app-carpool-view',
  templateUrl: './carpool-view.component.html',
})
export class CarpoolViewComponent implements OnInit, OnDestroy {
  activeIndex = 0;
  subscriptionRef: Subscription;
  visible = false;
  carpoolFormGroup!: FormGroup;
  roles: Record<string, string> = roles;
  isCreate: boolean | undefined = true;
  isViewMode: boolean | undefined = true;
  submitted = false;
  carpoolVisible = false;
  vehicles: Vehicle[] = [];
  tripType: TripType[];
  isRound = false;
  isReturn = false;
  startDate: Date = new Date();
  startAddressSubject: Subject<any> = new Subject<any>();
  endAddressSubject: Subject<any> = new Subject<any>();
  huddles: Huddle[];
  huddleId: string | null;
  carpoolId: string | null;
  carpoolList: any;
  leader: any;
  isLeader = true;
  selectedUser: User[] = [];
  isAcceptOrDeny = false;
  huddleView: Huddle | undefined;
  huddleCarpool = false;
  userInfo: UserDetails;
  isLoading = true;
  isPastCarpool = false;
  @Input() userPickerVisible = false;
  @Input() huddlers: Huddlers[];
  @Input() visibleChange: Observable<DialogEvent>;
  @Input() header = 'Dialog';
  @Input() view = 'RESPONDERS';
  @Input() width = '30vw';
  @Input() height = 'auto';
  @Input() fullHeight = false;
  @Input() fullWidth = false;
  @Input() styleClass: string;
  @Output() huddleDialogData = new EventEmitter();
  userDetail$: Observable<UserDetails>;
  userPickerEvent: Subject<any> = new Subject<any>();
  dialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();

  constructor(
    private huddleService: HuddleService,
    private formBuilder: FormBuilder,
    private vehicleService: VehicleService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private store: Store<AppState>,
  ) {
    this.tripType = [
      { name: 'Round', code: TripTypeEnum.R },
      { name: 'Depart', code: TripTypeEnum.D },
      { name: 'Return', code: TripTypeEnum.RT },
    ];
  }

  ngOnInit(): void {
    this.carpoolId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.visibleChange)
      this.subscriptionRef = this.visibleChange.subscribe((v: DialogEvent) => {
        if (v.dialogType === 'RESPONDERS') {
          this.visible = v.visible;
          this.activeIndex = v.activeIndex as number;
        } else if (v.dialogType === 'CARPOOL') {
          (v.isCreate || !v.isViewMode) && this.getVehicles();
          v.isCreate && !v.huddleCarpool && this.getHuddles();
          if (v.huddleCarpool) {
            const h: any = [v.huddleObj];
            this.huddleView = v.huddleObj;
            this.huddleCarpool = v.huddleCarpool;
            this.huddleId = this.huddleView?.id?.toString() || null;
            this.carpoolFormGroup.get('huddles')?.setValue(v.huddleObj);
            this.carpoolFormGroup
              .get('endAddress')
              ?.patchValue(
                v.huddleObj.huddle.parking || v.huddleObj.huddle.venue,
              );
          }
          this.isCreate = v.isCreate;
          this.isViewMode = v.isViewMode;
          this.visible = v.visible;
        }
      });
    this.carpoolFormGroup = this.formBuilder.group({
      vehicle: [null],
      huddles: [null],
      tripType: [this.tripType[0]],
      oneWayAllowed: [false],
      joinRequestRequired: [false],
      startTime: [null, Validators.required],
      returnTime: [null],
      notes: [''],
      startAddress: [null, Validators.required],
      endAddress: [null, Validators.required],
      externalCommChannelUrl: [''],
      seatingCapacity: [null, Validators.required],
    });
    this.getCarpool(this.carpoolId);
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.userInfo = user;
        }),
      );
    this.userDetail$.subscribe();
  }

  ngOnDestroy(): void {
    this.subscriptionRef && this.subscriptionRef.unsubscribe();
  }

  getVehicles() {
    this.vehicleService.getVehicles().subscribe({
      next: (data: any) => {
        this.vehicles = data;
      },
      error: (err) => {
        console.error('Request to get vehicles errored out.' + err.message);
      },
    });
  }

  getHuddles() {
    this.huddleService.getMyUpcomingHuddles().subscribe({
      next: (data: any) => {
        this.huddles = data;
        if (this.huddleId)
          this.carpoolFormGroup
            .get('huddles')
            ?.setValue(
              this.huddles.find((h) => h.id === Number(this.huddleId)),
            );
      },
      error: (err) => {
        console.error('Request to get vehicles errored out.' + err.message);
      },
    });
  }

  onSubmit() {
    const {
      tripType,
      vehicle,
      huddles,
      joinRequestRequired,
      oneWayAllowed,
      ...rest
    } = this.carpoolFormGroup.value;

    if (!this.isCreate) {
      rest.tripType = tripType.code;
      rest.vehicle = vehicle;
      rest.huddles = huddles;
      rest.joinRequestRequired = joinRequestRequired
        ? joinRequestRequired
        : false;
      rest.oneWayAllowed = oneWayAllowed ? oneWayAllowed : false;
    }

    const requestBody: any = {};
    let carpool: Carpool;

    for (const [k, v] of Object.entries(rest)) {
      if (v && this.isCreate) requestBody[k] = v;
      if (v && !this.isCreate && this.carpoolFormGroup.get(k)?.touched) {
        if (k === 'vehicle')
          requestBody['vehicleId'] = vehicle?.id ? vehicle.id : null;
        else if (k === 'huddles')
          requestBody['parentId'] = huddles?.id ? huddles.id : null;
        else if (k === 'joinRequestRequired')
          requestBody['parentId'] = huddles?.id ? huddles.id : null;
        else requestBody[k] = v;
      }
    }

    if (this.isCreate) {
      carpool = {
        ...requestBody,
        vehicleId: vehicle?.id ? vehicle.id : null,
        tripType: tripType.code,
        joinRequestRequired: joinRequestRequired ? joinRequestRequired : false,
        oneWayAllowed: oneWayAllowed ? oneWayAllowed : false,
        parentId: huddles?.huddle.id ? huddles.huddle.id : null,
        parentEntity: huddles?.huddle.id ? 'HUDDLE' : 'NONE',
      };
    } else {
      requestBody.id = this.carpoolId;
      carpool = requestBody;
    }

    if (this.carpoolFormGroup.valid) {
      const method = this.isCreate ? 'createCarpool' : 'updateCarpool';
      this.submitted = true;

      this.huddleService[method](carpool, this.carpoolId).subscribe({
        next: (data: any) => {
          this.submitted = false;
          this.visible = false;
          this.huddleDialogData.emit(data);
        },
        error: (err) => {
          this.submitted = false;
          console.error('Request to create carpool errored out.' + err.message);
        },
      });
    }
  }

  onStartAddressChange(e: any) {
    this.carpoolFormGroup.get('startAddress')?.patchValue(e);
    initMap('gmap-route').then((map) => {
      if (this.carpoolFormGroup.get('endAddress')?.value) {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        this.calculateAndDisplayRoute(directionsService, directionsRenderer);
      }
    });
  }

  onEndAddressChange(e: any) {
    this.carpoolFormGroup.get('endAddress')?.patchValue(e);
    initMap('gmap-route').then((map) => {
      if (this.carpoolFormGroup.get('startAddress')?.value) {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        this.calculateAndDisplayRoute(directionsService, directionsRenderer);
      }
    });
  }

  onTripTypeChange(e: any) {
    const { value } = e;
    const tripType = value.code;
    this.isRound = tripType === TripTypeEnum.R;
  }

  calculateAndDisplayRoute(
    directionsService: google.maps.DirectionsService,
    directionsRenderer: google.maps.DirectionsRenderer,
  ) {
    const waypts: google.maps.DirectionsWaypoint[] = [];
    const _startAddress: string =
      this.carpoolFormGroup.get('startAddress')?.value.formattedAddress;
    const _endAddress: string =
      this.carpoolFormGroup.get('endAddress')?.value.formattedAddress;
    directionsService
      .route({
        origin: _startAddress,
        destination: _endAddress,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
      });
  }

  renderAddress() {
    this.startAddressSubject.next({
      action: 'FORM',
      payload: {
        startAddress: this.carpoolFormGroup.get('startAddress')?.value,
      },
    });
    this.endAddressSubject.next({
      action: 'FORM',
      payload: {
        endAddress: this.carpoolFormGroup.get('endAddress')?.value,
      },
    });
    this.onEndAddressChange(this.carpoolFormGroup.get('endAddress')?.value);
  }

  getProfileImage() {
    return environment.apiUrl + '/api/user/' + this.userInfo.id + '/pic';
  }

  getProfileImageById(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  validateSeatingCapatcity(e: any) {
    const value = Number(e.currentTarget.value);
    if (value !== 0 && (value < 2 || value > 15))
      this.carpoolFormGroup
        .get('seatingCapacity')
        ?.setErrors({ capacity: true });
    else this.carpoolFormGroup.get('seatingCapacity')?.setErrors(null);
  }

  onVehicleChange(e: any) {
    this.carpoolFormGroup
      .get('seatingCapacity')
      ?.patchValue(e.value.seatingCapacity);
  }

  getCarpool(id: string | null) {
    if (id) {
      this.huddleService.getCarpool(Number(id)).subscribe({
        next: (data: any) => {
          this.isLoading = false;
          this.setCarpoolFields(data);
        },
        error: (err) => {
          console.error('Request to get carpool errored out.' + err.message);
          this.isLoading = false;
        },
      });
    }
  }

  setCarpoolFields(data: any) {
    const _tripType = this.tripType.find(
      (d: TripType) => d.code === data.tripType,
    );
    this.isRound = _tripType?.code === TripTypeEnum.R;
    this.carpoolFormGroup.get('vehicle')?.patchValue(data.vehicle);
    this.carpoolFormGroup
      .get('tripType')
      ?.patchValue(
        this.tripType.find((d: TripType) => d.code === data.tripType),
      );
    this.carpoolFormGroup
      .get('huddles')
      ?.patchValue({ huddle: data.parentObj });
    this.carpoolFormGroup
      .get('startTime')
      ?.patchValue(new Date(data.startTime));
    this.carpoolFormGroup
      .get('returnTime')
      ?.patchValue(new Date(data.returnTime));
    this.carpoolFormGroup.get('notes')?.patchValue(data.notes);
    this.carpoolFormGroup.get('oneWayAllowed')?.patchValue(data.oneWayAllowed);
    this.carpoolFormGroup
      .get('joinRequestRequired')
      ?.patchValue(data.joinRequestRequired);
    this.carpoolFormGroup
      .get('externalCommChannelUrl')
      ?.patchValue(data.externalCommChannelUrl);
    this.carpoolFormGroup.get('startAddress')?.patchValue(data.startAddress);
    this.carpoolFormGroup
      .get('seatingCapacity')
      ?.patchValue(data.seatingCapacity);
    this.carpoolFormGroup.get('endAddress')?.patchValue(data.endAddress);
    this.carpoolList = data.carpoolers;
    this.leader = data.leader;
    this.selectedUser = data.carpoolers.map((c: any) => ({
      ...c.member,
      disabled: true,
    }));
    this.isLeader = data.leader.id === this.userInfo.id;
    this.isPastCarpool = new Date() > new Date(data.startTime);
    this.renderAddress();
  }

  onInviteUser() {
    const seating = this.carpoolFormGroup.get('seatingCapacity')?.value;
    if (seating < this.selectedUser.length) {
      this.userPickerEvent.next(true);
      this.messageService.add({
        severity: 'error',
        summary: 'Seating Capacity',
        detail:
          'Total number of carpoolers can not exceed the seating capacity of the vehicle',
      });
      return;
    }

    this.huddleService
      .inviteUsers(Number(this.carpoolId), {
        usersToInvite: this.selectedUser.map((m) => m.id),
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: !data.error ? 'success' : 'error',
            detail: data.message,
            summary: 'Carpool Invitation',
          });
          this.userPickerVisible = false;
          this.getCarpool(this.carpoolId);
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            detail: err.error.message,
            summary: 'Carpool Invitation',
          });
        },
      });
  }

  onSelectedUser(user: User) {
    this.selectedUser.push(user);
  }

  onRemoveUser(id: number) {
    this.selectedUser = this.selectedUser.filter((u) => u.id !== id);
  }

  onInviteAction(action: string) {
    this.huddleService
      .inviteAction(Number(this.carpoolId), {
        accepted: action === 'ACCEPT' ? true : false,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: !data.error ? 'success' : 'error',
            detail: data.message,
            summary: 'Carpool',
          });
          this.userPickerVisible = false;
          this.getCarpool(this.carpoolId);
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            detail: err.error.message,
            summary: 'Carpool',
          });
        },
      });
  }

  onInviteClick() {
    this.userPickerVisible = true;
    this.userPickerEvent.next('onLoad');
    this.userPickerEvent.next(
      this.selectedUser.filter((u) => u.id !== this.userInfo.id),
    );
    this.userPickerEvent.next(true);
  }

  onHuddleChange(e: any) {
    const { value } = e;
    this.carpoolFormGroup
      .get('endAddress')
      ?.patchValue(value.huddle.parking || value.huddle.venue);
    this.renderAddress();
  }

  onCarpoolersRemove(carpoolId: number, carpoolerId: number) {
    this.huddleService
      .removeCarpoolers(Number(this.carpoolId), carpoolerId)
      .subscribe({
        next: () => {
          this.carpoolList = this.carpoolList.filter(
            (c: any) => c.id !== carpoolId,
          );
          this.selectedUser = this.carpoolList.map((c: any) => ({
            ...c.member,
            disabled: true,
          }));
          this.messageService.add({
            severity: 'success',
            summary: 'Carpooler',
            detail: 'Carpooler removed',
          });
        },
        error: (err) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Carpooler',
            detail: err.message,
          });
        },
      });
  }

  onEdit() {
    this.dialogEvent.next({
      visible: true,
      dialogType: 'CARPOOL',
      carpoolId: Number(this.carpoolId),
      isCreate: false,
      isViewMode: false,
    });
  }

  onHuddleData(data: any) {
    this.setCarpoolFields(data);
  }

  get f() {
    return this.carpoolFormGroup.controls;
  }
}
