<div class="surface-ground container" *ngIf="!isLoading">
  <div class="mx-auto max-w-[920px] p-2">
    <div class="linear max mx-auto mb-4 transition-all duration-500">
      <h2 class="h-level-2 mb-2">Upcoming Carpool</h2>
      <div class="shipping-item shrink grow basis-0 p-[4px]">
        <p-card
          class="mx-auto block md:mx-0 md:my-auto"
          styleClass="padding-clear carpool-list"
        >
          <ng-template pTemplate="header" *ngIf="carpool">
            <div
              class="flex grow items-center justify-between border-b p-3"
              *ngIf="carpool"
            >
              <div class="flex items-center">
                <p-avatar styleClass="mr-2" size="large" shape="circle">
                  <img
                    *ngIf="carpool.leader?.profilePicUrl"
                    [src]="profilePicUrl | image | async"
                    alt=""
                  />
                  <img
                    *ngIf="!carpool.leader.profilePicUrl"
                    src="../assets/images/user-pic-default.svg"
                    alt="default"
                  />
                </p-avatar>
                <div class="">
                  <p-button
                    *ngIf="userInfo.id !== carpool.leader.id"
                    [label]="carpool.leader.displayName"
                    styleClass="prevent primary"
                    [text]="true"
                  ></p-button>
                  <span *ngIf="userInfo.id === carpool.leader.id">You</span>

                  <span
                    >{{
                      userInfo.id === carpool.leader.id ? " are" : " is"
                    }}
                    leading this carpool to&nbsp;</span
                  >
                  <p-button
                    *ngIf="carpool.parentObj"
                    [label]="carpool.parentObj.name"
                    styleClass="prevent primary"
                    [text]="true"
                  ></p-button>
                  <p-button
                    *ngIf="!carpool.parentObj"
                    [label]="carpool.startAddress.name"
                    styleClass="prevent primary"
                    [text]="true"
                    (click)="openMap(carpool.startAddress.name)"
                  ></p-button>
                </div>
              </div>
              <button
                (click)="openMenu($event, carpool)"
                pButton
                class="prevent mx-3 h-[32px] rounded-full p-1"
              >
                <span class="material-icons prevent"> more_horiz </span>
              </button>
            </div>
          </ng-template>
          <div class="flex flex-col md:flex-row" *ngIf="carpool">
            <div
              class="flex-1"
              (click)="
                redirect(
                  ['carpool', 'view', 'new', '' + carpool.id],
                  'open_carpool'
                )
              "
            >
              <div
                class="grid cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 py-4 sm:grid-cols-6"
              >
                <div class="col-span-6 md:col-span-6">
                  <span class="highlight-text">
                    <span class="form-title">Start Time:</span>
                    {{ carpool.startTime | date: "EEE, MMM d, h:mm a" }}
                  </span>
                </div>
                <div
                  class="col-span-6 md:col-span-6"
                  *ngIf="carpool.returnTime"
                >
                  <span class="highlight-text">
                    <span class="form-title">Return Time:</span>
                    {{ carpool.returnTime | date: "EEE, MMM d, h:mm a" }}
                  </span>
                </div>
                <div class="col-span-6 md:col-span-6">
                  <span
                    ><span class="form-title">Start Address:</span>
                    {{ carpool.startAddress.name }}</span
                  >
                </div>
                <div class="col-span-6 md:col-span-6">
                  <span
                    ><span class="form-title">End Address:</span>
                    {{ carpool.endAddress.name }}</span
                  >
                </div>
                <div class="col-span-6 md:col-span-6">
                  <span
                    ><span class="form-title">Trip Type:</span>
                    {{
                      carpool.tripType === "ROUND"
                        ? "Round Trip"
                        : carpool.tripType === "DEPART"
                          ? "Depart Trip"
                          : "Return Trip"
                    }}</span
                  >
                </div>
                <div class="col-span-6 md:col-span-6">
                  <div
                    class="mb-1 flex grow flex-col items-start md:flex-row md:items-center"
                  >
                    <div class="flex flex-1 flex-col gap-3">
                      <span class="flex items-center gap-3">
                        <p-avatarGroup>
                          <p-avatar
                            *ngFor="
                              let carpooler of carpool.carpoolers.slice(0, 5)
                            "
                            shape="circle"
                            styleClass="inline-block align-middle"
                          >
                            <img
                              *ngIf="carpooler.member.profilePicUrl"
                              [src]="
                                getProfileImage(carpooler.member.id)
                                  | image
                                  | async
                              "
                              alt=""
                            />
                            <img
                              *ngIf="!carpooler.member.profilePicUrl"
                              src="../assets/images/user-pic-default.svg"
                              alt="default"
                            />
                          </p-avatar>
                          <p-avatar
                            *ngIf="carpool.carpoolers.length > 5"
                            [label]="
                              '+' + (carpool.carpoolers.length - 5).toString()
                            "
                            shape="circle"
                          ></p-avatar>
                        </p-avatarGroup>
                        <span>{{ carpool.carpoolers.length }} carpoolers</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p-divider
              layout="vertical"
              styleClass="mp-divider md:block hidden"
            ></p-divider>
            <p-divider styleClass="mp-divider block md:hidden"></p-divider>
            <div
              class="grid flex-1 cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 py-4 sm:grid-cols-6"
            >
              <div class="col-span-6 md:col-span-6">
                <h2 class="h-level-2">AI Assisted Smart Instructions</h2>
              </div>
              <div class="col-span-6 md:col-span-6">
                <div class="mb-2">
                  <span class="form-title">Summary</span>
                  <p-skeleton
                    *ngIf="isSkeletonLoading"
                    styleClass="mb-2"
                  ></p-skeleton>
                  <p-skeleton
                    *ngIf="isSkeletonLoading"
                    styleClass="mb-2"
                  ></p-skeleton>
                  <p *ngIf="!isSkeletonLoading">
                    {{ instructions.carpoolSummary }}
                  </p>
                </div>
                <div class="mb-2">
                  <span class="form-title">Carpool Ethics</span>
                  <p-skeleton
                    *ngIf="isSkeletonLoading"
                    styleClass="mb-2"
                  ></p-skeleton>
                  <p-skeleton
                    *ngIf="isSkeletonLoading"
                    styleClass="mb-2"
                  ></p-skeleton>
                  <p *ngIf="!isSkeletonLoading">
                    {{ instructions.carpoolEthics }}
                  </p>
                </div>
                <div class="mb-2">
                  <span class="form-title">Driving Instructions</span>
                  <p-skeleton
                    *ngIf="isSkeletonLoading"
                    styleClass="mb-2"
                  ></p-skeleton>
                  <p-skeleton
                    *ngIf="isSkeletonLoading"
                    styleClass="mb-2"
                  ></p-skeleton>
                  <p *ngIf="!isSkeletonLoading">
                    {{ instructions.drivingInstructions }}
                  </p>
                </div>
                <div class="mb-2">
                  <span class="form-title">Vehicle Snapshot</span>
                  <p-skeleton
                    *ngIf="isSkeletonLoading"
                    styleClass="mb-2"
                  ></p-skeleton>
                  <p-skeleton
                    *ngIf="isSkeletonLoading"
                    styleClass="mb-2"
                  ></p-skeleton>
                  <p *ngIf="!isSkeletonLoading">
                    {{ instructions.vehicleSnapshot }}
                  </p>
                </div>
                <div>
                  <span class="form-title">Huddle Snashot</span>
                  <p-skeleton
                    *ngIf="isSkeletonLoading"
                    styleClass="mb-2"
                  ></p-skeleton>
                  <p-skeleton
                    *ngIf="isSkeletonLoading"
                    styleClass="mb-2"
                  ></p-skeleton>
                  <span *ngIf="!isSkeletonLoading">{{
                    instructions.huddleSnapshot
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col md:flex-row" *ngIf="!carpool">
            <div class="flex-1">
              <div
                class="grid cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 py-4 sm:grid-cols-6"
              >
                <div class="col-span-6 md:col-span-6">
                  <h3 class="h-level-3">No upcoming carpool</h3>
                  <p>You do not have any upcoming carpool</p>
                  <div class="my-1 text-center">
                    <button
                      type="button"
                      pButton
                      (click)="onCarpool()"
                      class="mp-button mp-button-outlined mb-4 truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
                    >
                      <span>Create Carpool</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <p-divider
              layout="vertical"
              styleClass="mp-divider md:block hidden"
            ></p-divider>
            <p-divider styleClass="mp-divider block md:hidden"></p-divider>
            <div
              class="grid flex-1 cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 py-4 sm:grid-cols-6"
            >
              <div class="col-span-6 md:col-span-6">
                <h3 class="h-level-3">Going to huddle?</h3>
                <p>
                  Do you know, if you are going to Huddle, you can lead or join
                  a carpool from the huddle page to have fun drive time, make
                  new friends and save the enviroment at the time.
                </p>
                <div class="my-1 text-center">
                  <button
                    type="button"
                    pButton
                    (click)="redirect(['/huddle', 'huddles'], 'huddle_huddles')"
                    class="mp-button mp-button-outlined mb-4 truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
                  >
                    <span>Explore Huddles</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </p-card>
        <div *ngIf="carpool" class="mt-1 flex justify-end">
          <span class="h-level-3">Explore more at&nbsp;</span>
          <a class="primary h-level-3" [routerLink]="['/carpool', 'carpools']">
            Carpools
          </a>
        </div>
      </div>
    </div>
    <div class="linear max mx-auto mb-24 transition-all duration-500">
      <h2 class="h-level-2 mb-2">Other Carpools</h2>
      <div class="dashboard shrink grow basis-0 p-[4px]">
        <app-slider
          [isDark]="isDark"
          objectType="CARPOOL"
          [userInfo]="userInfo"
        ></app-slider>
      </div>
    </div>
  </div>
</div>

<p-menu
  #menu
  [model]="carpoolOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
>
  <ng-template pTemplate="item" let-item>
    <a
      *ngIf="item.id !== 'open_huddle'"
      (click)="redirect(['carpool', 'view', 'new', '' + carpool.id], item.id)"
      class="p-menuitem-link justify-content-between align-items-center flex p-3 dark:border-[#1F242F] dark:bg-[#1F242F] dark:text-slate-300 hover:dark:bg-[#1F242F]"
    >
      <div>
        <span class="ml-2"> {{ item.label }}</span>
      </div>
    </a>
    <a
      *ngIf="item.id === 'open_huddle' && !hasHuddle"
      (click)="redirect(['huddle', 'view', '' + carpool.parentObj.id])"
      class="p-menuitem-link justify-content-between align-items-center flex p-3 dark:border-[#1F242F] dark:bg-[#1F242F] dark:text-slate-300 hover:dark:bg-[#1F242F]"
    >
      <div>
        <span class="ml-2"> {{ item.label }}</span>
      </div>
    </a>
  </ng-template>
</p-menu>

<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>

<app-dialog
  [visibleChange]="dialogEvent.asObservable()"
  [view]="'CARPOOL'"
  header="Create"
  [userDetails]="userInfo"
  width="50vw"
></app-dialog>

<app-sharelink
  *ngIf="carpool"
  [event]="shareLinkEvent.asObservable()"
  objectType="CARPOOL"
  [objectId]="carpool.id"
></app-sharelink>

<p-confirmDialog #cd styleClass="md:w-5/12 w-10/12" appendTo="body">
  <ng-template pTemplate="header">
    <h3 class="h-level-3">Create a new Carpool</h3>
  </ng-template>
  <ng-template pTemplate="message">
    <p class="mt-2">
      Would you like to set up a carpool independently? It's recommended to
      start by creating a Huddle for your carpool group, and then create or join
      a carpool from within that Huddle.
    </p>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="mt-2 flex flex-col justify-start gap-4 xl:flex-row">
      <div class="order-2 flex-1 text-left xl:order-none">
        <button
          type="button"
          pButton
          (click)="cd.reject()"
          class="mp-button mp-button-outlined w-full justify-center rounded-md border px-5 py-2 leading-6 transition-all xl:w-auto"
        >
          Cancel
        </button>
      </div>
      <button
        pButton
        (click)="onConfirm()"
        class="mp-button mp-button-filled justify-center truncate rounded-md px-5 py-2 leading-6 shadow-sm"
      >
        Create Carpool
      </button>
      <button
        pButton
        (click)="onConfirm('/huddle/create')"
        class="mp-button mp-button-filled justify-center truncate rounded-md px-5 py-2 leading-6 shadow-sm"
      >
        Create Huddle for Carpools
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
