import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarpoolRoutingModule } from './carpool-routing.module';
import { CarpoolComponent } from './carpool.component';
import { CarpoolOverviewComponent } from './carpool-overview/carpool-overview.component';
import { CarpoolViewComponent } from './carpool-view/carpool-view.component';
import { CarpoolListComponent } from './carpool-list/carpool-list.component';
import { CarpoolViewNewComponent } from './carpool-view-new/carpool-view-new.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HuddleModule } from '../huddle/huddle.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
// import { EditorModule } from 'primeng/editor';
import { InputNumberModule } from 'primeng/inputnumber';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SkeletonModule } from 'primeng/skeleton';
import { CreateCarpoolComponent } from './create-carpool/create-carpool.component';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EditorModule } from 'primeng/editor';

@NgModule({
  declarations: [
    CarpoolComponent,
    CarpoolViewComponent,
    CarpoolListComponent,
    CarpoolOverviewComponent,
    CarpoolViewNewComponent,
    CreateCarpoolComponent,
  ],
  imports: [
    CommonModule,
    // HuddleModule,
    CarpoolRoutingModule,
    CardModule,
    ButtonModule,
    DialogModule,
    ReactiveFormsModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    DropdownModule,
    ProgressBarModule,
    TabMenuModule,
    FontAwesomeModule,
    ToastModule,
    CheckboxModule,
    CalendarModule,
    AvatarModule,
    MenuModule,
    AvatarGroupModule,
    DividerModule,
    TabViewModule,
    SharedModule,
    MatSidenavModule,
    ScrollPanelModule,
    AccordionModule,
    SidebarModule,
    ImageCropperModule,
    // EditorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    InfiniteScrollModule,
    ProgressSpinnerModule,
    SkeletonModule,
    InputTextModule,
    InputTextareaModule,
    EditorModule,
  ],
})
export class CarpoolModule {}
