import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarpoolComponent } from './carpool.component';
import { CarpoolOverviewComponent } from './carpool-overview/carpool-overview.component';
import { CarpoolListComponent } from './carpool-list/carpool-list.component';
import { CarpoolViewComponent } from './carpool-view/carpool-view.component';
import { CarpoolViewNewComponent } from './carpool-view-new/carpool-view-new.component';
import { authGuard } from '../../utilities';
import { CreateCarpoolComponent } from './create-carpool/create-carpool.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    component: CarpoolComponent,
    children: [
      {
        path: '',
        component: CarpoolOverviewComponent,
      },
      {
        path: 'carpools',
        component: CarpoolListComponent,
      },
      {
        path: 'create',
        component: CreateCarpoolComponent,
      },
      {
        path: 'view/:id',
        component: CarpoolViewComponent,
      },
      {
        path: 'view/new/:id',
        component: CarpoolViewNewComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarpoolRoutingModule {}
